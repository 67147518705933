import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 2xl:gap-5 gap-3" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "2xl:col-span-2 mt-2 lg:mt-0 lg:ml-5 2xl:ml-10" }
const _hoisted_7 = { class: "space-y-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/setting-information/your-green-options" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Your Green Options ")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "select-standard" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Select Standard")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["SelectStandardsForm"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "mt-2 text-left text-lg 2xl:text-xl font-bold" }, " Scope 2 Renewables Performance ", -1)),
          _createVNode($setup["ColumnChart"], {
            class: _normalizeClass(_ctx.styles['column-size']),
            data: $setup.dataScope_2,
            colors: ['#ffeecc', '#ffeecc', '#5e5e5e'],
            data_2: $setup.dataRenewables_2,
            colors_2: ['#addfa4', '#addfa4', '#5e5e5e'],
            seriesName: "Scope 2 (kWh)",
            seriesName_2: "Renewables (kWh)",
            lineSeriesName: "Renewables (%)",
            lineData: $setup.dataTarget_2,
            lineMarker: $setup.dataMarker,
            lineMarkerName: "Target (%)",
            yTitle: "kWh",
            legendStyle: {
              position: 'bottom',
              fontSize: 12,
              padding: [15, 0, 0, 0],
            },
            withExtraYScale: true,
            lineAllowExtraYScale: true
          }, null, 8, ["class", "data", "data_2", "lineData", "lineMarker"])
        ])
      ])
    ])
  ]))
}