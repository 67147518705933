import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import DialogModule from "@/store/UI/Dialog";
import DialogGuideRECsStandards from "@ems/containers/GreenOptions/TheDialog/GuideRECsStandards.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectStandards.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();

const Standard = ref("Carbon Neutral");

const radioManually = ref("");
const inputManually = ref();

const handleClickGuide = () => {
  DialogModule.showModal({ component: DialogGuideRECsStandards });
};

const handleChangeStandard = (value: any) => {
  if (value === "Carbon Neutral" || value === "SS 673" || value === "RE100") {
    radioManually.value = "";
    inputManually.value = 0;
  }
};

const onSubmit = () => {
  router.push(
    "/setting-information/your-green-options/select-standard/buy-recs"
  );
  // router.push("/setting-information/your-green-options/select-standard/buy-order");
};

const __returned__ = { router, Standard, radioManually, inputManually, handleClickGuide, handleChangeStandard, onSubmit, BaseSvgIcon, get DialogModule() { return DialogModule }, DialogGuideRECsStandards, ref, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})