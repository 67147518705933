import { createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "space-y-3 2xl:space-y-6 my-3" }
const _hoisted_3 = { class: "text-left text-lg 2xl:text-xl font-bold" }
const _hoisted_4 = {
  class: "flex items-center",
  style: { marginTop: '-4px' }
}
const _hoisted_5 = { class: "-ml-4" }
const _hoisted_6 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _cache[6] || (_cache[6] = _createTextVNode(" Which standards would you like to achieve? ")),
        _createVNode(_component_el_tooltip, {
          effect: "dark",
          content: "What do these standards mean?",
          placement: "top"
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: "cursor-pointer group text-white hover:text-blue-500 text-2xl ml-1",
              onClick: _withModifiers($setup.handleClickGuide, ["prevent"])
            }, [
              _createVNode($setup["BaseSvgIcon"], {
                class: "inline-block w-6 h-6",
                name: "warning"
              })
            ])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_el_radio_group, {
        modelValue: $setup.Standard,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.Standard) = $event)),
        onChange: $setup.handleChangeStandard,
        class: "flex flex-col"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_radio, { label: "Carbon Neutral" }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createElementVNode("span", { class: "text-white" }, "Carbon Neutral", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_el_radio, { label: "SS 673" }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createElementVNode("span", { class: "text-white" }, "SS 673", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_el_radio, { label: "RE100" }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createElementVNode("span", { class: "text-white" }, "RE100", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_radio, {
          modelValue: $setup.radioManually,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.radioManually) = $event)),
          label: "1",
          onChange: _cache[2] || (_cache[2] = 
            () => {
              $setup.Standard = '';
            }
          )
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("span", { class: "text-white" }, "Others: Please specify", -1)
          ])),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_el_input_number, {
            class: "w-20 inputfield inputfield__underline",
            placeholder: "",
            controls: false,
            modelValue: $setup.inputManually,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.inputManually) = $event)),
            onClick: _cache[4] || (_cache[4] = _withModifiers(
              () => {
                $setup.Standard = '';
                $setup.radioManually = '1';
              }
            , ["prevent"]))
          }, null, 8, ["modelValue"]),
          _cache[11] || (_cache[11] = _createTextVNode(" % "))
        ])
      ]),
      _cache[12] || (_cache[12] = _createElementVNode("hr", { class: "border-gray-400" }, null, -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--red-primary",
          onClick: _cache[5] || (_cache[5] = _withModifiers(() => $setup.onSubmit(), ["prevent"]))
        }, " Next ")
      ])
    ])
  ]))
}